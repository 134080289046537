import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import style from "./events.module.css"

import Event from "../components/event"

const EventsPast = () => {
  const data = useStaticQuery(graphql`
    query {
      allEvent(
        filter: { collection: { eq: "past" } }
        sort: { fields: startDate, order: ASC }
      ) {
        nodes {
          id
          name
          startDate
          endDate
          location
          url
          slug
        }
      }
    }
  `)

  const events = data.allEvent.nodes
  return (
    <div className="event-list">
      <h2 className={style.events__header}>Past Events:</h2>
      <ul className={style.events__list}>
        {events.map(event => (
          <Event key={event.id} event={event} />
        ))}
      </ul>
    </div>
  )
}

export default EventsPast
