import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import PostIndexBody from "./post-index-body"

const ArticlesLatest = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fields: { collection: { eq: "articles" } } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 5
      ) {
        totalCount
        edges {
          node {
            excerpt
            id
            frontmatter {
              title
              date
              subject
              author
              featimg {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges

  return (
    <ul>
      {posts.map(({ node }, index) => (
        <PostIndexBody key={index} post={node} index={index} />
      ))}
    </ul>
  )
}

export default ArticlesLatest
