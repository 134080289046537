import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import style from "./index.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EventsUpcoming from "../components/events-upcoming"
import EventsPast from "../components/events-past"
import PortfolioList from "../components/portfolio-list"
import ArticlesLatest from "../components/articles-latest"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="something"
        description="this is a test"
        image="/images/mor10logo.png"
        pathname="/"
        article
      />
      <section className={style.bio}>
        <div className={style.bio__container}>
          <figure className={style.bio__image}>
            <Img
              fluid={data.bioImage.childImageSharp.fluid}
              alt="Morten Rand-Hendriksen"
            />
          </figure>
          <div className={style.bio__blurb}>
            <h1 className={style.section__heading}>Heisann!</h1>
            <p>
              Welcome, human and/or internet using entity visiting this site.
              Once a blog to document my learnings as I explored Microsoft’s
              then-new offering Expression Suite, this site has gone through
              many permutations before ending up in its current incarnation: an
              online repository of my somewhat connected thoughts on the
              internet and how it shapes us.
            </p>
            <p>
              On this site you’ll find my blog with articles ranging from the
              technical to the theoretical, and in time a page about my speaking
              engagements future, present, and past.
            </p>
          </div>
        </div>
      </section>
      <section className={style.speaking}>
        <h2 className={style.section__heading}>Speaking</h2>
        <div className={style.speaking__container}>
          <figure className={style.speaking__image}>
            <Img
              fluid={data.speakingImage.childImageSharp.fluid}
              alt="Morten Rand-Hendriksen"
            />
          </figure>
          <div className={style.speaking__blurb}>
            <p>
              One of my many titles is &ldquo;Speaker at Tech Events.&rdquo;
              Over the years my talkhs have covered a wide range of topics; from
              web standards to open source contribution to advanced tools and
              techniques to human-centered design.
            </p>
            <p>
              These days the primary focus of my talks is around the human
              element in tech: How to design and build things for the web that
              move us forward together. Current topics range from tech and
              design ethics to how to stay on the cutting edge of an ever-moving
              industry.
            </p>
          </div>
        </div>
        <div className={style.events}>
          <EventsUpcoming />
          <EventsPast />
        </div>
      </section>
      <section className={style.portfolio}>
        <h2 className={style.section__heading}>Portfolio</h2>
        <div className={style.portfolio__list}>
          <PortfolioList posts={data.portfolioQuery.edges} />
        </div>
      </section>
      <section className={style.writing}>
        <h2 className={style.section__heading}>Writing</h2>
        <ArticlesLatest />
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    bioImage: file(relativePath: { eq: "morten_profile.JPG" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1600, srcSetBreakpoints: [200, 340, 520, 890]) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    speakingImage: file(relativePath: { eq: "morten_RRS.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    portfolioQuery: allMarkdownRemark(
      filter: { fields: { collection: { eq: "portfolio" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 6
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            author
            featimg {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default IndexPage
